import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

/**
 * A private route component that checks if the user is authenticated
 * and redirects to the login page if not.
 *
 * @param {Object} props The component props
 * @param {React.ReactElement} props.children The content to render if the user is authenticated
 * @return {React.ReactElement} The private route component
 */
const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth();

  // If we are still checking if the user is authenticated, show a loading spinner
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // If the user is authenticated, render the content
  // Otherwise, redirect to the login page
  return isAuthenticated ? children : <Navigate to="/login" />;
};

/**
 * @typedef {Object} PrivateRouteProps
 * @property {React.ReactElement} children The content to render if the user is authenticated
 * @prop {PrivateRouteProps} props The component props
 */

export default PrivateRoute;
