import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import pamperedChefLogo from '../assets/logo2.png'; 
import { Button, Drawer } from 'antd';

const Sidebar = () => {
  const location = useLocation(); // Get the current route location

  // Function to determine if a tab is active
  const isActive = (path) => location.pathname === path;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  const showDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Sidebar Content
  const sidebarContent = (
    <div
  className={`d-flex flex-column p-3 ${isMobileView ? '' : 'shadow bg-light'}`}
  style={{ width: '200px', height: "100%", fontFamily: "Poppins" }}
>
      {/* Logo with Dashboard Link */}
      <div className="text-center mb-4">
        <Link to="/dashboard">
          <img
            src={pamperedChefLogo}
            alt="Logo"
            style={{
              width: '100%', // Adjust the size of the logo
              cursor: 'pointer', // Change the cursor to a pointer to indicate it's clickable
            }}
          />
        </Link>
      </div>

      <div className="overflow-auto" >
        <Link
          onClick={closeDrawer}
          to="/dashboard"
          className={`text-dark mb-3 text-decoration-none d-block text-center ${
            isActive('/dashboard') ? 'active' : ''
            
          }`}
          style={{
            fontSize: '16px', // Set font size to 16px
            backgroundColor: isActive('/dashboard') ? '#D4A76A' : '#E9C99C', // Change color for active link
            borderRadius: '5px',
            padding: '10px',
            textAlign: 'center',
          }}
        >
          Dashboard
        </Link>
        <Link
          onClick={closeDrawer}
          to="/active-jobs"
          className={`text-dark mb-3 text-decoration-none d-block text-center ${
            isActive('/active-jobs') ? 'active' : ''
          }`}
          style={{
            fontSize: '16px', // Set font size to 16px
            backgroundColor: isActive('/active-jobs') ? '#D4A76A' : '#E9C99C', // Change color for active link
            borderRadius: '5px',
            padding: '10px',
            textAlign: 'center',
          }}
        >
          Active Jobs
        </Link>
        <Link
          onClick={closeDrawer}
          to="/history"
          className={`text-dark mb-3 text-decoration-none d-block text-center ${
            isActive('/history') ? 'active' : ''
          }`}
          style={{
            fontSize: '16px', // Set font size to 16px
            backgroundColor: isActive('/history') ? '#D4A76A' : '#E9C99C', // Change color for active link
            borderRadius: '5px',
            padding: '10px',
            textAlign: 'center',
          }}
        >
          History
        </Link>
      </div>
    </div>
  );

  return (
    <div className="d-flex">
      {/* Hamburger button and Drawer for mobile view */}
      {isMobileView ? (
        <>
          <div style={{ position: "fixed", top: "18px", left: "12px", zIndex: 1000}}>
            <Button type="text" onClick={showDrawer} style={{ fontSize: "24px"}}>
              ☰
            </Button>
          </div>
          <Drawer
            placement="left"
            onClose={closeDrawer}
            open={isDrawerOpen}
            style={{width:"70%",backgroundColor:"#F8F9FA",padding:"0px"}}
            styles={{
              wrapper: { boxShadow: "none" },
            }}
         >
            {sidebarContent}
          </Drawer>
        </>
      ) : (
        // Static Sidebar for desktop view
        <div>{sidebarContent}</div>
      )}

    </div>
  );
};

export default Sidebar;
