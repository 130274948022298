import React, { useState, useEffect } from 'react';
import Card from '../components/Card'; // Import reusable Card component
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const Dashboard = () => {
  const [activeJobs, setActiveJobs] = useState(0);
  const [historyJobs, setHistoryJobs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch active and completed jobs counts
  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      setError(null);
      try {
        const activeJobsResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/job/jobs/byStatus?status=inprogress`
        );
        const completedJobsResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/job/jobs/byStatus?status=complete`
        );
        setActiveJobs(activeJobsResponse.data.jobs.length || 0); // Count active jobs
        setHistoryJobs(completedJobsResponse.data.jobs.length || 0); // Count completed jobs
      } catch (err) {
        console.error('Error fetching job counts:', err);
        setError('Failed to load job counts. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  return (
    <div className="container my-5 " style={{fontFamily:"Poppins"}}>
      <h1 className="mb-4 text-center">Dashboard</h1>

      {loading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : error ? (
        <div className="alert alert-danger text-center">{error}</div>
      ) : (
        <div className="row g-4 d-flex align-items-center justify-content-center">
          {/* Job Counter Cards */}
          <div className="col-md-6 col-lg-6 d-flex align-items-center justify-content-center">
            <Card title="In Progress Jobs" count={activeJobs} color="#008c7a" />
          </div>
          <div className="col-md-6 col-lg-6 d-flex align-items-center justify-content-center">
            <Card title="Completed Jobs" count={historyJobs} color="#008c7a" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
