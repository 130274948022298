import React, { useState, useEffect } from "react";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Swal from "sweetalert2";
import { Table, Button, Spin, DatePicker } from 'antd';
import dayjs from 'dayjs';

/**
 * A spinner loader component to indicate that data is being fetched.
 * @returns {React.ReactElement} A spinner loader component.
 */
const Loader = ({ size = "default" }) => {
  const spinnerStyle = size === "small" 
    ? { width: "15px", height: "15px", margin: "0" } // Smaller size with no margins
    : {};

  return (
    <div className="d-flex justify-content-center align-items-center">
      
      <div className="spinner-grow text-primary" role="status" style={spinnerStyle}></div>
      <div className="spinner-grow text-secondary" role="status" style={spinnerStyle}></div>
      <div className="spinner-grow text-success" role="status" style={spinnerStyle}></div>
      
    </div>
  );
};


const History = () => {
  const [date, setDate] = useState(''); 
  const [jobs, setJobs] = useState([]);
  const [jobLoading, setJobLoading] = useState(false);
  const [pcdLoading, setPcdLoading] = useState({}); // State for PCD loader for each job
  const [niaLoading, setNiaLoading] = useState({}); // State for NIA loader for each job
  const [ssLoading, setSsLoading] = useState({}); // State for SS loader for each job
  /**
   * Fetches the list of active jobs from the API.
   * Sets the jobLoading state to true before making the API call and sets it to false when the call is complete.
   * If the API call fails, it logs the error and shows an alert to the user.
   * @returns {Promise<void>}
   */
  const fetchJobs = async () => {
    setJobLoading(true);
    try {
      const params = { status: "complete" };
      // Add startDate and endDate to query parameters if selected
      if (date) {
        params.date = date; // Add date to query parameters if selected
      }
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/job/jobs/byStatus`,
        { params } // Pass query parameters
      );
      // Store the fetched data in the jobs state
      setJobs(response.data.jobs);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      // Show an alert to the user if the API call fails
      alert("Failed to fetch jobs. Please try again.");
    } finally {
      // Reset the jobLoading state when the API call is complete
      setJobLoading(false);
    }
  };
  
  /**
   * Calls the Parent-Child Distribution API to generate an Excel file.
   * Sets the loading state for the specific jobId during the API call.
   * If the API call is successful, generates an Excel file and triggers a download.
   * If the API call fails, shows an alert to the user.
   * Resets the loading state when the API call is complete.
   * 
   * @param {number} jobId The ID of the job to generate the Excel file for.
   */
  const callParentChildDistroApi = async (jobId) => {
    setPcdLoading((prevState) => ({ ...prevState, [jobId]: true })); // Set loading state for the specific jobId
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/parentChildDistro/CreateParentChildDistro`,
        {
          jobId: jobId.toString(),
        }
      );
  
      const data = response.data.data; // Assume this is the array of objects
  
      if (data && data.length > 0) {
        // Generate Excel file
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "ParentChildDistro");
  
        // Create a Blob and trigger download
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const blob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `ParentChildDistro_${jobId}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        fetchJobs();
  
        // Show success alert for file generation
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Parent-Child Distribution data processed successfully and Excel file downloaded!",
          confirmButtonText: "OK",
        });
      } else {
        // Show warning alert if no data is available
        Swal.fire({
          icon: "warning",
          title: "No Data",
          text: "No data available to generate the Excel file.",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error calling Parent-Child Distribution API:", error);
  
      // Show error alert for API call failure
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to call the Parent-Child Distribution API. Please try again.",
        confirmButtonText: "Retry",
      });
    } finally {
      setPcdLoading((prevState) => ({ ...prevState, [jobId]: false })); // Reset loading state for the specific jobId
    }
  };
  /**
   * Calls the SummaryReport API to generate an Excel file.
   * Sets the loading state for the specific jobId during the API call.
   * If the API call is successful, generates an Excel file and triggers a download.
   * If the API call fails, shows an alert to the user.
   * Resets the loading state when the API call is complete.
   * @param {number} jobId The ID of the job to generate the Excel file for.
   */
  const callSummeryReportApi = async (jobId) => {
    setNiaLoading((prevState) => ({ ...prevState, [jobId]: true })); // Set loading state for the specific jobId
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/summeryReport/CreateSummeryReport`,
        { jobId: jobId.toString() }
      );
  
      const data = response.data;
  
      if (data && data.length > 0) {
        // Generate Excel file
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "SummaryReport");
  
        // Create a Blob and trigger download
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `SummaryReport_${jobId}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        fetchJobs();
  
        // Show success alert
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Summary Report processed successfully and Excel file downloaded!",
          confirmButtonText: "OK",
        });
      } else {
        // Show warning alert if no data is available
        Swal.fire({
          icon: "warning",
          title: "No Data",
          text: "No data available to generate the Excel file.",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error calling SummaryReport API:", error);
  
      // Show error alert for API call failure
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to call the Summary Report API. Please try again.",
        confirmButtonText: "Retry",
      });
    } finally {
      setNiaLoading((prevState) => ({ ...prevState, [jobId]: false })); // Reset loading state for the specific jobId
    }
  };
  /**
   * Calls the StopSellReport API to generate an Excel file.
   * Sets the loading state for the specific jobId during the API call.
   * If the API call is successful, generates an Excel file and triggers a download.
   * If the API call fails, shows an alert to the user.
   * Resets the loading state when the API call is complete.
   * @param {number} jobId The ID of the job to generate the Excel file for.
   */
  const callStopSellReportApi = async (jobId) => {
    setSsLoading((prevState) => ({ ...prevState, [jobId]: true })); // Set loading state for the specific jobId
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/stopSellReport/CreateStopSellReport`,
        { jobId: jobId.toString() }
      );
  
      const data = response.data.data;
  
      if (data && data.length > 0) {
        // Generate Excel file
        const worksheet = XLSX.utils.json_to_sheet(data); // Converts the JSON data to an Excel worksheet
        const workbook = XLSX.utils.book_new(); // Creates a new workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "StopSellReport"); // Adds the worksheet to the workbook
  
        // Create a Blob and trigger download
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `StopSellReport_${jobId}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        fetchJobs();
  
        // Show success alert
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Stop Sell Report processed successfully and Excel file downloaded!",
          confirmButtonText: "OK",
        });
      } else {
        // Show warning alert if no data is available
        Swal.fire({
          icon: "warning",
          title: "No Data",
          text: "No data available to generate the Excel file.",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error calling StopSellReport API:", error);
  
      // Show error alert for API call failure
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to call the Stop Sell Report API. Please try again.",
        confirmButtonText: "Retry",
      });
    } finally {
      setSsLoading((prevState) => ({ ...prevState, [jobId]: false })); // Reset loading state for the specific jobId
    }
  };
  

  useEffect(() => {
    fetchJobs();
  }, [date]);

 return (
  <div className="container mt-4" style={{fontFamily:"Poppins"}}>
  <h2 className="text-center">Job History</h2>
  <div className="mb-4">
    <label htmlFor="date" className="block mb-2">
      Select Date: 
    </label>
    <DatePicker
      value={date ? dayjs(date) : null}
      onChange={(date) => setDate(date ? date.format("YYYY-MM-DD") : "")}
      style={{
       marginLeft: "10px",
        width: "200px",
        fontSize: "14px",
      }}
    />
  </div>
  <Table
    className="overflow-auto"
    columns={[
      {
        title: "Job ID",
        dataIndex: "jobId",
        key: "jobId",
        align: "center",
        width: "15%",
      },
      {
        title: "Time",
        dataIndex: "createdAt",
        key: "createdAt",
        align: "center",
        width: "25%",
        render: (text) => new Date(text).toLocaleString(),
      },
      {
        title: "NIA",
        key: "nia",
        align: "center",
        width: "15%",
        render: (_, record) =>
          record.isProduct &&
          record.isBom &&
          record.isIMR &&
          record.isDSR &&
          record.isDementra &&
          record.isUCR &&
          record.isRMA &&
          record.isKP ? (
            <Button
              onClick={() => callSummeryReportApi(record.jobId)}
              disabled={niaLoading[record.jobId]}
              style={{
                backgroundColor: "",
                borderRadius: "5px",
                border: "none",
                width: "40px",
                height: "40px",
              }}
              icon={
                niaLoading[record.jobId] ? (
                  <Spin size="small" />
                ) : (
                  <FontAwesomeIcon icon={faDownload} />
                )
              }
            />
          ) : (
            "N/A"
          ),
      },
      {
        title: "PCD",
        key: "pcd",
        align: "center",
        width: "15%",
        render: (_, record) =>
          record.isSummery ? (
            <Button
              onClick={() => callParentChildDistroApi(record.jobId)}
              disabled={pcdLoading[record.jobId]}
              style={{
                backgroundColor: "",
                borderRadius: "5px",
                border: "none",
                width: "40px",
                height: "40px",
              }}
              icon={
                pcdLoading[record.jobId] ? (
                  <Spin size="small" />
                ) : (
                  <FontAwesomeIcon icon={faDownload} />
                )
              }
            />
          ) : (
            "N/A"
          ),
      },
      {
        title: "SS",
        key: "ss",
        align: "center",
        width: "15%",
        render: (_, record) =>
          record.isSummery && record.isPCD ? (
            <Button
              onClick={() => callStopSellReportApi(record.jobId)}
              disabled={ssLoading[record.jobId]}
              style={{
                backgroundColor: "",
                borderRadius: "5px",
                border: "none",
                width: "40px",
                height: "40px",
              }}
              icon={
                ssLoading[record.jobId] ? (
                  <Spin size="small" />
                ) : (
                  <FontAwesomeIcon icon={faDownload} />
                )
              }
            />
          ) : (
            "N/A"
          ),
      },
      {
        title: "Status",
        dataIndex: "status",
        backgroundColor: "#0000FF",
        key: "status",
        align: "center",
        width: "15%",
        render: (status) => (status === "complete" ? "Complete" : status),
      },
    ]}
    dataSource={jobs.map((job) => ({ ...job, key: job.jobId }))}
    pagination={{ pageSize: 10 }}
    locale={{
     emptyText: jobLoading ? <Spin tip="Loading jobs..." /> : "No jobs found",
   }}
    style={{
      borderRadius: "8px",
      overflow: "hidden",
    }}
    components={{
      header: {
        cell: (props) => (
          <th
            {...props}
            style={{
              backgroundColor: "",
              textAlign: "center",
              padding: "12px",
              ...props.style,
            }}
          />
        ),
      },
    }}
  />
</div>
 );
};

export default History;
