import React from 'react';

const Card = ({ title, count, color }) => (
  <div
    className="card text-white"
    style={{
      backgroundColor: color,
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
      width:'60%',
    }}
  >
    <div
      className="card-body d-flex flex-column justify-content-center align-items-center" // Flexbox styles to center the text
    >
      <h5 className="card-title text-center display-3">{count}</h5>
      <p className="card-text text-center" >
        {title}
      </p>
    </div>
  </div>
);

export default Card;
