import React, { createContext, useState, useContext, useEffect } from 'react';

// Create Auth Context
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Track loading state for app initialization

  useEffect(() => {
    const savedAuthState = localStorage.getItem('isAuthenticated');
    if (savedAuthState === 'true') {
      setIsAuthenticated(true);
    }
    setIsLoading(false); // Loading complete
  }, []);

  /**
   * Login function to authenticate user and persist
   * authentication state in localStorage
   * @param {string} email The user's email address
   * @param {string} password The user's password
   * @returns {boolean} Whether the login was successful
   */
  const login = (email, password) => {
    // Compare with hardcoded credentials for demonstration purposes
    // TODO: Replace with actual authentication API call
    if (email === 'admin@shopdev.co' && password === 'Shopdev123!@#') {
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true'); // Persist state
      return true;
    }
    return false;
  };

  /**
   * Logout function to reset authentication state
   * and remove persisted state in localStorage
   */
  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated'); // Clear persisted state
  };

  /**
   * Returns the current authentication state and
   * functions for login and logout
   */

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
