import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import JobTable from "../components/jobTable";
/**
 * A spinner loader component to indicate that data is being fetched.
 * @returns {React.ReactElement} A spinner loader component.
 */
const Loader = ({ size = "default" }) => {
  const spinnerStyle =
    size === "small"
      ? { width: "15px", height: "15px", margin: "0" } // Smaller size with no margins
      : {};

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div
        className="spinner-grow text-primary"
        role="status"
        style={spinnerStyle}
      ></div>
      <div
        className="spinner-grow text-secondary"
        role="status"
        style={spinnerStyle}
      ></div>
      <div
        className="spinner-grow text-success"
        role="status"
        style={spinnerStyle}
      ></div>
    </div>
  );
};

const ActiveJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jobLoading, setJobLoading] = useState(false);
  const [fileUploading, setFileUploading] = useState(false); // State for file uploading
  const [pcdLoading, setPcdLoading] = useState({}); // State for PCD loader for each job
  const [niaLoading, setNiaLoading] = useState({}); // State for NIA loader for each job
  const [ssLoading, setSsLoading] = useState({}); // State for SS loader for each job
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [fileType, setFileType] = useState("");
  const [file, setFile] = useState(null);
  const [showModal, setShowModal] = useState(false);

  /**
   * Fetches the list of active jobs from the API.
   * Sets the jobLoading state to true before making the API call and sets it to false when the call is complete.
   * If the API call fails, it logs the error and shows an alert to the user.
   * @returns {Promise<void>}
   */
  const fetchJobs = async () => {
    setJobLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/job/jobs/byStatus?status=inprogress`
      );
      // Store the fetched data in the jobs state
      setJobs(response.data.jobs);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      // Show an alert to the user if the API call fails
      alert("Failed to fetch jobs. Please try again.");
    } finally {
      // Reset the jobLoading state when the API call is complete
      setJobLoading(false);
    }
  };

  /**
   * Creates a new job in the database.
   * Sets the loading state to true before making the API call and sets it to false when the call is complete.
   * If the API call fails, it logs the error and shows an alert to the user.
   * @returns {Promise<void>}
   */
  const createJob = async () => {
    setLoading(true); // Set loading state to true
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/job/jobs`,
        {
          status: "inprogress",
          createdBy: "Admin",
        }
      );

      // Show a success alert if the job is created successfully
      Swal.fire({
        icon: "success",
        title: "Job Created",
        text: "Job created successfully!",
        confirmButtonText: "OK",
      });

      // Fetch the list of active jobs again to update the UI
      fetchJobs();
    } catch (error) {
      console.error("Error creating job:", error);

      // Show an error alert if the API call fails
      Swal.fire({
        icon: "error",
        title: "Job Creation Failed",
        text: "Failed to create job. Please try again.",
        confirmButtonText: "Retry",
      });
    } finally {
      // Reset the loading state when the API call is complete
      setLoading(false);
    }
  };

  /**
   * Handles the file upload process.
   * Validates the file, file type, and selected job ID before uploading.
   * Sets the file uploading state to true during the upload process.
   * Alerts the user upon success or failure and resets the relevant states.
   *
   * @returns {Promise<void>}
   */
  const handleFileUpload = async () => {
    // Check if file, fileType, and selectedJobId are provided
    if (!file || !fileType || !selectedJobId) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please select a file type and upload a file.",
      });
      return;
    }

    const allowedExtensions = /(\.xls|\.xlsx|\.xlsm|\.csv)$/i; // Regular expression for .xls, .xlsx,.xlsm and csv files
    if (!allowedExtensions.test(file.name)) {
      Swal.fire({
        icon: "error",
        title: "Invalid File Type",
        text: "Only .xls, .xlsx,.xlsm (Excel) and CSV files are allowed. Please upload a valid file.",
        confirmButtonText: "OK",
      });
      return;
    }

    // URL mappings for different file types
    const urlMapping = {
      PimProduct: `${process.env.REACT_APP_API_BASE_URL}/dataPosting/UploadPimDataToDb`,
      BomProduct: `${process.env.REACT_APP_API_BASE_URL}/dataPosting/UploadBomDataToDb`,
      IMR: `${process.env.REACT_APP_API_BASE_URL}/dataPosting/UploadInventoryDataToDb`,
      DSR: `${process.env.REACT_APP_API_BASE_URL}/dataPosting/UploadDSRDataToDb`,
      Demantra: `${process.env.REACT_APP_API_BASE_URL}/dataPosting/UploadDemantraDataToDb`,
      UnsubmittedCart: `${process.env.REACT_APP_API_BASE_URL}/dataPosting/UploadUnsubmittedCartDataToDb`,
      RMABuffer: `${process.env.REACT_APP_API_BASE_URL}/dataPosting/UploadRMABufferToDb`,
      ProtectedKit: `${process.env.REACT_APP_API_BASE_URL}/dataPosting/UploadKitProtectionToDb`,
      UpdateSummaryReport: `${process.env.REACT_APP_API_BASE_URL}/dataPosting/UpdateSummaryReportToDb`,
    };

    // Get the upload URL for the given file type
    const uploadUrl = urlMapping[fileType];
    const formData = new FormData();
    formData.append("Files", file);
    formData.append("jobId", selectedJobId);

    setFileUploading(true); // Start file uploading state
    try {
      // Make the API call to upload the file
      const response = await axios.post(uploadUrl, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      // Check statusCode in response
      if (response.data.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message || "File uploaded successfully!",
          confirmButtonText: "OK",
        });

        fetchJobs(); // Refresh the job list
        setShowModal(false); // Close the modal
        setFile(null); // Reset the file
        setFileType(""); // Reset the file type
      } else {
        Swal.fire({
          icon: "error",
          title: "Upload Failed",
          text:
            response.data.message || "Something went wrong. Please try again.",
          confirmButtonText: "Retry",
        });
      }
    } catch (error) {
      console.error("Error uploading file:", error);

      // Show error alert
      const errorMessage =
        error.response?.data?.message ||
        "Failed to upload file. Please try again.";
      Swal.fire({
        icon: "error",
        title: "Upload Failed",
        text: errorMessage,
        confirmButtonText: "Retry",
      });
    } finally {
      setFileUploading(false); // End file uploading state
    }
  };

  /**
   * Calls the Parent-Child Distribution API to generate an Excel file.
   * Sets the loading state for the specific jobId during the API call.
   * If the API call is successful, generates an Excel file and triggers a download.
   * If the API call fails, shows an alert to the user.
   * Resets the loading state when the API call is complete.
   *
   * @param {number} jobId The ID of the job to generate the Excel file for.
   */
  const callParentChildDistroApi = async (jobId) => {
    setPcdLoading((prevState) => ({ ...prevState, [jobId]: true })); // Set loading state for the specific jobId
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/parentChildDistro/CreateParentChildDistro`,
        {
          jobId: jobId.toString(),
        }
      );

      const data = response.data.data; // Assume this is the array of objects

      if (data && data.length > 0) {
        // Generate Excel file
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "ParentChildDistro");

        // Create a Blob and trigger download
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const blob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `ParentChildDistro_${jobId}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        fetchJobs();

        // Show success alert for file generation
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Parent-Child Distribution data processed successfully and Excel file downloaded!",
          confirmButtonText: "OK",
        });
      } else {
        // Show warning alert if no data is available
        Swal.fire({
          icon: "warning",
          title: "No Data",
          text: "No data available to generate the Excel file.",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error calling Parent-Child Distribution API:", error);

      // Show error alert for API call failure
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to call the Parent-Child Distribution API. Please try again.",
        confirmButtonText: "Retry",
      });
    } finally {
      setPcdLoading((prevState) => ({ ...prevState, [jobId]: false })); // Reset loading state for the specific jobId
    }
  };
  /**
   * Calls the SummaryReport API to generate an Excel file.
   * Sets the loading state for the specific jobId during the API call.
   * If the API call is successful, generates an Excel file and triggers a download.
   * If the API call fails, shows an alert to the user.
   * Resets the loading state when the API call is complete.
   * @param {number} jobId The ID of the job to generate the Excel file for.
   */
  const callSummeryReportApi = async (jobId) => {
    setNiaLoading((prevState) => ({ ...prevState, [jobId]: true })); // Set loading state for the specific jobId
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/summeryReport/CreateSummeryReport`,
        { jobId: jobId.toString() }
      );

      const data = response.data;

      if (data && data.length > 0) {
        // Generate Excel file
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "SummaryReport");

        // Create a Blob and trigger download
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const blob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `SummaryReport_${jobId}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        fetchJobs();

        // Show success alert
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Summary Report processed successfully and Excel file downloaded!",
          confirmButtonText: "OK",
        });
      } else {
        // Show warning alert if no data is available
        Swal.fire({
          icon: "warning",
          title: "No Data",
          text: "No data available to generate the Excel file.",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error calling SummaryReport API:", error);

      // Show error alert for API call failure
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to call the Summary Report API. Please try again.",
        confirmButtonText: "Retry",
      });
    } finally {
      setNiaLoading((prevState) => ({ ...prevState, [jobId]: false })); // Reset loading state for the specific jobId
    }
  };
  /**
   * Calls the StopSellReport API to generate an Excel file.
   * Sets the loading state for the specific jobId during the API call.
   * If the API call is successful, generates an Excel file and triggers a download.
   * If the API call fails, shows an alert to the user.
   * Resets the loading state when the API call is complete.
   * @param {number} jobId The ID of the job to generate the Excel file for.
   */
  const callStopSellReportApi = async (jobId) => {
    setSsLoading((prevState) => ({ ...prevState, [jobId]: true })); // Set loading state for the specific jobId
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/stopSellReport/CreateStopSellReport`,
        { jobId: jobId.toString() }
      );

      const data = response.data.data;

      if (data && data.length > 0) {
        // Generate Excel file
        const worksheet = XLSX.utils.json_to_sheet(data); // Converts the JSON data to an Excel worksheet
        const workbook = XLSX.utils.book_new(); // Creates a new workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "StopSellReport"); // Adds the worksheet to the workbook

        // Create a Blob and trigger download
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const blob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `StopSellReport_${jobId}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        fetchJobs();

        // Show success alert
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Stop Sell Report processed successfully and Excel file downloaded!",
          confirmButtonText: "OK",
        });
      } else {
        // Show warning alert if no data is available
        Swal.fire({
          icon: "warning",
          title: "No Data",
          text: "No data available to generate the Excel file.",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error calling StopSellReport API:", error);

      // Show error alert for API call failure
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to call the Stop Sell Report API. Please try again.",
        confirmButtonText: "Retry",
      });
    } finally {
      setSsLoading((prevState) => ({ ...prevState, [jobId]: false })); // Reset loading state for the specific jobId
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  return (
    <div className="container mt-4" style={{fontFamily:"Poppins"}}>
      <h2 className="text-center" >Active Jobs</h2>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <button
          className="btn"
          onClick={createJob}
          disabled={loading}
          style={{
            backgroundColor: "#E9C99C", // Background color for the link
            borderRadius: "5px", // Optional: add rounded corners to the background
            padding: "10px", // Adjust the size of the link to match a button
            textAlign: "center", // Center the text inside the link
          }}
        >
          {loading ? "Processing..." : "Create Job"}
        </button>
        {jobLoading && <Loader />}
      </div>
      <div>
        <JobTable
          jobs={jobs}
          niaLoading={niaLoading}
          pcdLoading={pcdLoading}
          ssLoading={ssLoading}
          setSelectedJobId={setSelectedJobId}
          setShowModal={setShowModal}
          callSummeryReportApi={callSummeryReportApi}
          callParentChildDistroApi={callParentChildDistroApi}
          callStopSellReportApi={callStopSellReportApi}
          loading={jobLoading}
        />
      </div>
      {/* Modal for File Upload */}
      {showModal && (
        <div className="modal show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Upload File</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setShowModal(false);
                    setFile(null);
                    setFileType("");
                  }}
                ></button>
              </div>
              <div className="modal-body">
                {fileUploading ? (
                  <Loader />
                ) : (
                  <>
                    <p>Job ID: {selectedJobId}</p>
                    <div className="mb-3">
                      <label className="form-label">File Type:</label>
                      <select
                        className="form-select"
                        value={fileType}
                        onChange={(e) => setFileType(e.target.value)}
                      >
                        <option value="">Select File Type</option>
                        <option value="PimProduct">PimProduct</option>
                        <option value="BomProduct">BomProduct</option>
                        <option value="IMR">IMR</option>
                        <option value="DSR">DSR</option>
                        <option value="Demantra">Demantra</option>
                        <option value="UnsubmittedCart">UnsubmittedCart</option>
                        <option value="RMABuffer">RMABuffer</option>
                        <option value="ProtectedKit">ProtectedKit</option>
                        <option value="UpdateSummaryReport">
                          Update Summary Report
                        </option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">File:</label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                    </div>
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-primary me-2"
                        onClick={handleFileUpload}
                      >
                        Upload
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={() => {
                          setShowModal(false);
                          setFile(null);
                          setFileType("");
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActiveJobs;
