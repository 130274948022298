import React,{useState} from "react";
import { Table, Button, Spin,Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const JobTable = ({
  jobs,
  niaLoading,
  pcdLoading,
  ssLoading,
  setSelectedJobId,
  setShowModal,
  callSummeryReportApi,
  callParentChildDistroApi,
  callStopSellReportApi,
  loading,
}) => {
  const [searchText, setSearchText] = useState("");

  const handleSearch = (text) => {
    setSearchText(text.toLowerCase().trim());
  };
  
  const filteredJobs = (jobs??[])?.filter?.((job) => {
    const searchWords = searchText.split(" ").filter(Boolean);
    const jobIdString = job.jobId.toString().toLowerCase();
    const statusString = job.status.toLowerCase();
  
    return searchWords.every(
      (word) =>
        jobIdString === word ||
        statusString.includes(word) 
    );
  });
  
  
  
  
  const columns = [
    {
      title: "Job ID",
      dataIndex: "jobId",
      key: "jobId",
      align: "center",
      width: "8%",
      sorter: (a, b) => a.jobId - b.jobId,
      defaultSortOrder: "descend",
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: "12%",
      render: (text) => new Date(text).toLocaleString(),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "PIM",
      dataIndex: "isProduct",
      key: "isProduct",
      align: "center",
      width: "5%",
      render: (value) => (value ? "Yes" : "No"),
    },
    {
      title: "BOM",
      dataIndex: "isBom",
      key: "isBom",
      align: "center",
      width: "5%",
      render: (value) => (value ? "Yes" : "No"),
    },
    {
      title: "IMR",
      dataIndex: "isIMR",
      key: "isIMR",
      align: "center",
      width: "5%",
      render: (value) => (value ? "Yes" : "No"),
    },
    {
      title: "DSR",
      dataIndex: "isDSR",
      key: "isDSR",
      align: "center",
      width: "5%",
      render: (value) => (value ? "Yes" : "No"),
    },
    {
      title: "Demantra",
      dataIndex: "isDementra",
      key: "isDementra",
      align: "center",
      width: "7%",
      render: (value) => (value ? "Yes" : "No"),
    },
    {
      title: "USC",
      dataIndex: "isUCR",
      key: "isUCR",
      align: "center",
      width: "5%",
      render: (value) => (value ? "Yes" : "No"),
    },
    {
      title: "RMA",
      dataIndex: "isRMA",
      key: "isRMA",
      align: "center",
      width: "5%",
      render: (value) => (value ? "Yes" : "No"),
    },
    {
      title: "KP",
      dataIndex: "isKP",
      key: "isKP",
      align: "center",
      width: "5%",
      render: (value) => (value ? "Yes" : "No"),
    },
    {
      title: "NIA",
      key: "nia",
      align: "center",
      width: "5%",
      render: (_, record) =>
        record.isProduct &&
        record.isBom &&
        record.isIMR &&
        record.isDSR &&
        record.isDementra &&
        record.isUCR &&
        record.isRMA &&
        record.isKP ? (
          <Button
            onClick={() => callSummeryReportApi(record.jobId)}
            disabled={niaLoading[record.jobId]}
            type="primary"
            shape="circle"
            icon={
              niaLoading[record.jobId] ? (
                <Spin size="small" />
              ) : (
                <FontAwesomeIcon icon={faDownload} />
              )
            }
          />
        ) : (
          <FontAwesomeIcon icon={faTimesCircle} style={{ color: "red" }} />
        ),
    },
    {
      title: "PCD",
      key: "pcd",
      align: "center",
      width: "5%",
      render: (_, record) =>
        record.isSummery ? (
          <Button
            onClick={() => callParentChildDistroApi(record.jobId)}
            disabled={pcdLoading[record.jobId]}
            type="primary"
            shape="circle"
            icon={
              pcdLoading[record.jobId] ? (
                <Spin size="small" />
              ) : (
                <FontAwesomeIcon icon={faDownload} />
              )
            }
          />
        ) : (
          <FontAwesomeIcon icon={faTimesCircle} style={{ color: "red" }} />
        ),
    },
    {
      title: "SS",
      key: "ss",
      align: "center",
      width: "5%",
      render: (_, record) =>
        record.isSummery && record.isPCD ? (
          <Button
            onClick={() => callStopSellReportApi(record.jobId)}
            disabled={ssLoading[record.jobId]}
            type="primary"
            shape="circle"
            icon={
              ssLoading[record.jobId] ? (
                <Spin size="small" />
              ) : (
                <FontAwesomeIcon icon={faDownload} />
              )
            }
          />
        ) : (
          <FontAwesomeIcon icon={faTimesCircle} style={{ color: "red" }} />
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "10%",
      render: (status) => (status==="inprogress"?"In Progress":status),
    },      
    {
      title: "Actions",
      key: "actions",
      align: "center",
      width: "18%",
      render: (_, record) => (
        <Button
          type="success"
          style={{backgroundColor:"#E9C99C"}}
          onClick={() => {
            setSelectedJobId(record.jobId);
            setShowModal(true);
          }}
        >
          Upload File
        </Button>
      ),
    },
  ];

  const dataSource = filteredJobs.map((job) => ({
    ...job,
    key: job.jobId,
  }));

  return (
    <div className="w-full" style={{ fontFamily: "Poppins" }}>
      <Input
        placeholder="Search by Job ID or Status"
        onChange={(e) => handleSearch(e.target.value)}
        style={{ marginBottom: "20px", width: "300px" }}
      />
      <Table
        columns={columns}
        dataSource={dataSource}
        bordered
        locale={{
          emptyText: loading ? <Spin tip="Loading jobs..." /> : "No jobs found",
        }}
        pagination={{ pageSize: 10 }}
        style={{
          margin: "20px 0",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          overflow: "auto",
        }}
        size="small"
      />
    </div>
  );
};

export default JobTable;
